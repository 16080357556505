import clsx from "clsx";
import { Paragraph } from "~/components/ui/typography";
import { Link } from "~/components/ui/link";
import { Icon } from "~/components/ui/icons";
import type {
	NavItem,
	Link as LinkType,
	MainImage,
} from "~/types/sanity-schema";
import { asLink } from "~/sanity/sanity-helpers";
import {
	getImageBuilder,
	getImageProps,
	getImageAltProp,
} from "~/sanity/images";
import { Image } from "~/components/ui/image";

type SubNavLinkGroupProps = {
	headerVariant?: "default" | "withLink";
	headerLink?: LinkType;
	headerImage?: MainImage;
	headerTitle?: string;
	layout?: "vertical" | "horizontal";
	gradientBg?: boolean;
	items?: Array<NavItem>;
};

const renderImage = (image?: MainImage) => {
	if (!image) return null;
	return (
		<div className="aspect-[16/9] w-full max-w-[300px] overflow-hidden rounded-lg">
			<Image
				className="size-full object-cover"
				{...getImageProps(
					getImageBuilder(image, { alt: getImageAltProp(image) }),
					{
						widths: [400, 500, 800],
						sizes: ["(min-width:1280px) 300px", "50vw"],
					}
				)}
			/>
		</div>
	);
};

const renderNavItem = (item: NavItem, layout: "vertical" | "horizontal") => {
	const { navLink, icon, image, variant } = item;
	const url = asLink(navLink);
	if (!url) return null;

	const isExternal = Boolean(navLink?.externalUrl) && !navLink?.internalLink;
	const isLinkToEnglishPage =
		navLink?.locale === "en" && navLink?.pageLocale !== "en";
	const linkProps = isLinkToEnglishPage ? { "data-lang": "en" } : {};
	const isHighlight = variant === "highlight";

	return (
		<li key={url} className={clsx({ "flex-1": layout === "horizontal" })}>
			<Link
				aria-label={navLink?.title}
				to={url}
				trackingText={navLink?.title}
				trackingPosition="menu"
				{...linkProps}
				className={clsx(
					"group block p-3 hover:rounded-lg hover:bg-secondary-10/60",
					{ "flex gap-3": icon }
				)}
			>
				{isHighlight && image && (
					<div className="bg-secondary aspect-[16/9] w-full max-w-[300px] overflow-hidden rounded-lg">
						<Image
							className="size-full object-cover"
							{...getImageProps(
								getImageBuilder(image, { alt: getImageAltProp(image) }),
								{
									widths: [400, 500, 800],
									sizes: ["(min-width:1280px) 300px", "50vw"],
								}
							)}
						/>
					</div>
				)}
				{icon && (
					<Image
						height="24px"
						className="h-6"
						{...getImageProps(
							getImageBuilder(icon, { alt: getImageAltProp(icon) })?.width(24)
						)}
					/>
				)}
				<Paragraph
					size="body-xsmall"
					className="mb-1 inline-flex items-center gap-2"
					color="current"
					fontWeight="font-medium"
					{...(isLinkToEnglishPage && { "data-label": "link-title" })}
				>
					{navLink?.title}
					{isHighlight ? (
						<Icon
							name="arrow-right"
							color="primary"
							className="transition-all group-hover:translate-x-2"
						/>
					) : isExternal ? (
						<Icon
							name="external-link"
							color="primary"
							className="transition-all group-hover:-translate-y-1 group-hover:translate-x-1"
						/>
					) : null}
				</Paragraph>
				{navLink?.caption && (
					<Paragraph size="body-xsmall" color="secondary">
						{navLink?.caption}
					</Paragraph>
				)}
			</Link>
		</li>
	);
};

export function SubNavLinkGroup({
	headerVariant = "default",
	headerLink,
	headerImage,
	headerTitle,
	layout = "vertical",
	gradientBg,
	items,
}: SubNavLinkGroupProps) {
	if (!items) return null;

	const isLinkToEnglishPage =
		headerLink?.locale === "en" && headerLink?.pageLocale !== "en";
	const linkProps = isLinkToEnglishPage ? { "data-lang": "en" } : {};

	return (
		<div>
			{headerVariant === "withLink" ? (
				<Link
					aria-label={headerTitle}
					className="group relative mb-3 flex flex-col gap-3 p-3 hover:rounded-lg hover:bg-secondary-10/60"
					to={asLink(headerLink) || ""}
					trackingText={headerTitle}
					trackingPosition="menu"
					{...linkProps}
				>
					<Paragraph
						color="secondary"
						className="uppercase"
						size="body-xsmall"
						{...(isLinkToEnglishPage && { "data-label": "link-title" })}
					>
						{headerTitle}
						<Icon
							name="arrow-right"
							color="primary"
							className="ml-2 inline-block transition-all group-hover:translate-x-2"
						/>
					</Paragraph>

					{/* Separate div for border to avoid link hover effect */}
					{!headerImage && !headerLink?.caption && (
						<div
							className={clsx(
								"absolute bottom-[-1px] left-3 h-[1px] w-[calc(100%-16px)]",
								{
									"bg-grey-10": !gradientBg,
									"bg-white": gradientBg,
								}
							)}
						/>
					)}
					{renderImage(headerImage)}
					{headerLink?.caption && (
						<Paragraph size="body-xsmall" color="secondary">
							{headerLink?.caption}
						</Paragraph>
					)}
				</Link>
			) : (
				<Paragraph
					size="body-xsmall"
					className="border-stroke mx-3 mb-3 border-b py-3 pr-3 uppercase"
					color="secondary"
				>
					{headerTitle}
				</Paragraph>
			)}
			<ul className={clsx({ "flex flex-row gap-3": layout === "horizontal" })}>
				{items.map((item) => renderNavItem(item, layout))}
			</ul>
		</div>
	);
}
