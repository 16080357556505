import { useState, useEffect, useRef } from "react";
import { useLocation, useSubmit } from "react-router";
import { getLanguageLocaleKey, Dictionary } from "~/utils/language";
import type { Language } from "~/utils/language";
import { usePageAvailableLocales, useRootData } from "~/hooks/common";
import { Icon } from "~/components/ui/icons";
import { Paragraph } from "./ui/typography";
import { useSharedContent } from "~/hooks/localization";
import { spTrackWebInteraction } from "~/utils/tracking";

interface Props {
	iconOnly?: boolean;
	color?: string;
}

export function LanguageSelector({ iconOnly = false }: Props) {
	const location = useLocation();
	const submit = useSubmit();
	const dropdownRef = useRef<HTMLDivElement>(null);

	const { locale: activeLocale } = useRootData();
	const { t } = useSharedContent(Dictionary.LANGUAGE);
	const availableLocales = usePageAvailableLocales();

	const langItems: Array<Language> = ["en", ...availableLocales];
	const [isOpen, setIsOpen] = useState(false);

	function onLanguageChange(locale: Language) {
		const form = new FormData();
		form.append("pathName", location.pathname);
		form.append("search", location.search);
		form.append("locale", locale);

		submit(form, {
			method: "post",
			replace: true,
			action: "/api/set-language",
		});

		spTrackWebInteraction({
			object: "language",
			action: "switch",
			value: locale,
		});

		setIsOpen(false);
	}

	useEffect(() => {
		function handleClickOutside(event: MouseEvent) {
			if (
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target as Node)
			) {
				setIsOpen(false); // Close dropdown when clicking outside
			}
		}

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	return (
		<div className="relative flex items-center gap-4" ref={dropdownRef}>
			<button
				onClick={() => setIsOpen((prev) => !prev)}
				className="flex items-center gap-3 p-2 text-theme-primary focus:outline-none dark:text-grey-40"
				aria-expanded={isOpen}
				aria-haspopup="true"
			>
				<span className="sr-only">Change language</span>
				<Icon name="language" />
				{!iconOnly && (
					<>
						<Paragraph color="primary">
							{t(getLanguageLocaleKey(activeLocale))}
						</Paragraph>
						<Icon name="chevron-down" />
					</>
				)}
			</button>

			{isOpen && (
				<div
					className="border-stroke absolute right-0 top-8 z-50 mt-2 rounded-lg border bg-white p-3 shadow-lg dark:border-grey-40 dark:bg-grey-100"
					role="menu"
				>
					{langItems.map((item) => {
						const isActive = item === activeLocale;

						return (
							<button
								key={item}
								onClick={() => onLanguageChange(item)}
								className={`block w-full rounded-lg px-4 py-2 text-left text-sm hover:bg-grey-5 dark:hover:bg-grey-90 ${
									isActive ? "font-medium text-theme-primary" : "text-primary"
								}`}
								role="menuitem"
							>
								{t(getLanguageLocaleKey(item))}
							</button>
						);
					})}
				</div>
			)}
		</div>
	);
}
